import React from "react";

const MorabaaIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "70%" }} className="morabaa-icon m-auto" viewBox="0 0 951.74 951.74">
        <g id="Layer_4" data-name="Layer 4">
            <g>
                <path
                    className="bg"
                    d="M977.68,264.94V738.29a239,239,0,0,1-239.2,239.19H265.13A239,239,0,0,1,25.94,738.29V264.94A239,239,0,0,1,265.13,25.74H738.48a239,239,0,0,1,239.2,239.2Z"
                    transform="translate(-25.94 -25.74)"></path>
                <path className="curve" d="M864.55,770a109.51,109.51,0,0,1-216,0h65.18a46.5,46.5,0,0,0,85.62,0Z" transform="translate(-25.94 -25.74)"></path>
                <path
                    className="fill-white"
                    d="M754.32,507A109.47,109.47,0,0,0,655.2,663H586.66V508h-63v244.2A47.61,47.61,0,0,1,476.1,799.8v63A110.67,110.67,0,0,0,586.65,752.62c0-.13,0-.25,0-.38V726H754.32a109.5,109.5,0,0,0,0-219Zm0,156a46.5,46.5,0,1,1,46.5-46.5A46.55,46.55,0,0,1,754.32,663Z"
                    transform="translate(-25.94 -25.74)"></path>
                <path
                    className="fill-white"
                    d="M356.39,799.73v63H245.94v0A109.62,109.62,0,0,1,137.37,753.21h63a46.55,46.55,0,0,0,46.5,46.5v0Z"
                    transform="translate(-25.94 -25.74)"></path>
                <circle className="fill-white" cx="418.62" cy="736.94" r="31.5"></circle>
                <path
                    className="fill-white"
                    d="M475,508.05V615.46A110.69,110.69,0,0,1,364.47,726H245.94v0A109.62,109.62,0,0,1,137.36,616.5h0a109.5,109.5,0,0,1,219,.26h-63a46.5,46.5,0,0,0-93,0h0A46.55,46.55,0,0,0,246.86,663v0H364.47A47.61,47.61,0,0,0,412,615.46V508.05Z"
                    transform="translate(-25.94 -25.74)"></path>
            </g>
        </g>
    </svg>
);

export default MorabaaIcon;
